<template>
  <UCard
    :ui="{
      root: 'group hover:ring-yellow-400 bg-neutral-900/60 overflow-hidden backdrop-blur-lg relative hover:ring-2 duration-300 flex flex-col',
      header: 'p-0 sm:p-0',
      body: 'p-2 sm:p-4 sm:pb-3 -mt-1 h-full border-none',
    }"
  >
    <template #header>
      <div class="relative aspect-[16/9] overflow-hidden">
        <USkeleton
          class="absolute inset-0 h-full w-full rounded-none object-cover duration-300 group-hover:scale-105"
        />
      </div>
    </template>
    <div class="relative">
      <div class="flex space-x-1">
        <USkeleton class="h-4 w-1/2" />
      </div>
      <USkeleton class="mt-3 h-6 w-2/3" />
      <div class="mt-2 space-y-1">
        <USkeleton class="h-4 w-full" />
        <USkeleton class="mt-1 h-4 w-full" />
        <USkeleton class="mt-1 h-4 w-1/3" />
      </div>
    </div>
  </UCard>
</template>
